import React, { useState, useEffect } from 'react'
import {
  TextField,
  Button,
  Typography,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import useStyles from './styles'
import { createPost, updatePost } from '../../actions/posts'
import 'react-circular-progressbar/dist/styles.css'
import { useNavigate, useLocation } from 'react-router-dom'

const Form = ({ currentId, setCurrentId }, props) => {
  const navigate = useNavigate()
  const bytesToMegaBytes = (bytes) => bytes / 1024 ** 2
  const dispatch = useDispatch()
  const [multipleProgress, setMultipleProgress] = useState(0)
  const success = useSelector((state) => state.posts.status)
  const { state } = useLocation()

  const [uploadStatus, setUploadStatus] = useState(false)
  const [postData, setPostData] = useState({
    title: '',
    message: '',
    multipleFiles: '',
    fileSize: '',
    category: '',
    categoryLocation: ''
  })
  const post = useSelector((state) =>
    currentId ? state.posts.find((message) => message._id === currentId) : null
  )

  const classes = useStyles()
  const user = JSON.parse(localStorage.getItem('profile'))
  useEffect(() => {
    if (post) setPostData(post)
    if (success) {
      clear()
      setUploadStatus(true)
    }
  }, [post, success])

  const clear = () => {
    setCurrentId(0)
    setPostData({
      title: '',
      message: '',
      multipleFiles: '',
      category: '',
      categoryLocation: ''
    })
  }

  const MultipleFileChange = (e) => {
    const { files } = e.target
    // console.log(files)
    Array.from(files).forEach((file) => {
      const fileSize = bytesToMegaBytes(file.size)
      // console.log(fileSize + 'MB')
      if (fileSize < 300) {
        setPostData({
          ...postData,
          multipleFiles: e.target.files,
          fileSize: ''
        })
        setMultipleProgress(0)
      } else {
        e.target.value = null
        setPostData({
          ...postData,
          fileSize:
            'you must make sure the file size is less than or equal to 300MB.'
        })
      }
    })
  }
  const multipleFileOptions = {
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent
      const percentage = Math.floor(((loaded / 1000) * 100) / (total / 1000))
      setMultipleProgress(percentage)
    }
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    if (postData.category === '') {
      alert('Please select your location!')
    } else if (postData.categoryLocation === '') {
      alert('Please select your category!')
    } else if (postData.multipleFiles.length > 0) {
      const formData = new FormData()
      formData.append('title', postData.title)
      formData.append('category', postData.category)
      formData.append('categoryLocation', postData.categoryLocation)
      formData.append('message', postData.message)
      formData.append('name', user?.result?.name)
      formData.append('totalFile', postData.multipleFiles.length)
      for (let i = 0; i < postData.multipleFiles.length; i++) {
        // const element =multipleFiles[i]
        formData.append('multipleFiles', postData.multipleFiles[i])
      }
      // console.log(postData.multipleFiles)

      // await multipleFileUpload(formData)
      // dispatch(createPost({formData }))

      if (currentId === 0) {
        dispatch(createPost(formData, multipleFileOptions, navigate))
        setUploadStatus(false)

        clear()
      } else {
        dispatch(
          updatePost(currentId, { ...postData, name: user?.result?.name })
        )
        setUploadStatus(false)

        clear()
      }
    } else {
      alert('Please select file to upload!')
    }
  }
  // if (!user?.result?.name) {
  //   return (
  //     <Paper className={classes.paper}>
  //       <Typography variant='h6' align='center'>
  //         Please Sign In to upload images/videos.
  //       </Typography>
  //     </Paper>
  //   )
  // }
  return (
    <form
      encType='multipart/form-data'
      autoComplete='off'
      noValidate
      className={`${classes.root} ${classes.form}`}
      onSubmit={handleSubmit}
    >
      <Paper className={classes.boxOne}>
        <Typography variant='h6'>
          {currentId ? `Editing "${post.title}"` : 'Select Your Location'}
        </Typography>
        <FormControl fullWidth>
          <InputLabel id='subDemo-simple-select-label'>Location</InputLabel>

          <Select
            labelId='subDemo-simple-select-label'
            id='subDemo-simple-select'
            value={postData.category}
            label='Category'
            onChange={(e) =>
              setPostData({ ...postData, category: e.target.value })
            }
          >
            <MenuItem selected='selected' value=''>
              Please select your location
            </MenuItem>
            <MenuItem value='Scottsdale'>Scottsdale</MenuItem>
            <MenuItem value='Dallas'>Dallas</MenuItem>
            <MenuItem value='Boston'>Boston</MenuItem>
            <MenuItem value='Chicago'>Chicago</MenuItem>

            <MenuItem value='Houston'>Houston</MenuItem>
            <MenuItem value='Philadelphia'>Philadelphia</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id='subDemo-simple-select-label-cate'>
            Category
          </InputLabel>

          <Select
            labelId='subDemo-simple-select-label-cate'
            id='subDemo-simple-select-cate'
            value={postData.categoryLocation}
            label='Category'
            onChange={(e) =>
              setPostData({ ...postData, categoryLocation: e.target.value })
            }
          >
            <MenuItem selected='selected' value=''>
              Please select your category
            </MenuItem>
            <MenuItem value='Laser'>Laser</MenuItem>
            <MenuItem value='Injection'>Injection</MenuItem>
          </Select>
        </FormControl>
      </Paper>
      <Paper className={classes.boxTwo}>
        <Typography variant='h6'>
          {currentId ? `Editing "${post.title}"` : 'Upload File'}
        </Typography>
        <TextField
          name='title'
          variant='outlined'
          label='Title'
          fullWidth
          value=''
          className={classes.hiddenInput}
          type='hidden'
          // value={postData.title}
          onChange={(e) => setPostData({ ...postData, title: e.target.value })}
        />
        <TextField
          name='message'
          variant='outlined'
          label='Message'
          fullWidth
          multiline
          minRows={4}
          // value={postData.message}
          className={classes.hiddenInput}
          type='hidden'
          onChange={(e) =>
            setPostData({ ...postData, message: e.target.value })
          }
        />
        <div className={classes.fileInput}>
          <input
            type='file'
            className={classes.formControlFile}
            name='files'
            onChange={(e) => MultipleFileChange(e)}
          />
          <CircularProgressbar
            className={classes.CircularProgressbar}
            value={multipleProgress}
            text={`${multipleProgress}%`}
            styles={buildStyles({
              rotation: 0.25,
              strokeLinecap: 'butt',
              textSize: '16px',
              pathTransitionDuration: 0.5,
              pathColor: `rgba(255,136,136, ${multipleProgress / 100})`,
              textColor: '#f88',
              trailColor: '#d6d6d6',
              backgroundColor: '#3e98c7'
            })}
          />
        </div>
        <p>{postData.fileSize}</p>
        {/* <p>
          {state ? null : uploadStatus ? 'Files upload successfully' : null}
        </p> */}
        <Button
          className={classes.buttonSubmit}
          variant='contained'
          color='primary'
          size='large'
          type='submit'
          fullWidth
        >
          Upload
        </Button>
      </Paper>
    </form>
  )
}

export default Form
