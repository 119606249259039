import React, { useState, useEffect } from 'react'
import { Container, Grow, Grid, Paper } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import Posts from '../Posts/Posts'
import { getPosts } from '../../actions/posts'
import { signinStudent } from '../../actions/auth'
import { useHistory, useLocation, useNavigate } from 'react-router-dom'
import Paginate from '../Pagination'
import Form from '../Form/Form'
function useQuery() {
  return new URLSearchParams(useLocation().search)
}
const Home = () => {
  const [currentId, setCurrentId] = useState(0)
  const user = JSON.parse(localStorage.getItem('profile'))
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const query = useQuery()
  const page = query.get('page') || 1
  const studentLogin = {
    email: 'student@nationallaserinstitute.com',
    password: '123456'
  }

  useEffect(() => {
    if (user) {
      dispatch(getPosts(page, user.result._id))
    } else {
      dispatch(signinStudent(studentLogin, navigate))

      // navigate('/upload')
    }
  }, [currentId, dispatch])

  return (
    <div>
      <Grow in>
        <Container>
          <Grid
            container
            justifyContent='space-between'
            alignItems='stretch'
            spacing={3}
          >
            <Grid item xs={12} sm={12}>
              <Posts setCurrentId={setCurrentId} />
              <Paper elevation={6}>
                <Paginate page={page} />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Grow>
    </div>
  )
}

export default Home
