import React, { useState, useEffect } from 'react'
import { Container, Grow, Grid, Paper } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation, useNavigate } from 'react-router-dom'
import Posts from '../Posts/Posts'
import { getPosts } from '../../actions/posts'
import { signinStudent } from '../../actions/auth'
import Paginate from '../Pagination'
import Form from '../Form/Form'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}
const Upload = () => {
  const [currentId, setCurrentId] = useState(0)
  const user = JSON.parse(localStorage.getItem('profile'))
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const query = useQuery()
  const page = query.get('page') || 1
  const studentLogin = {
    email: 'student@nationallaserinstitute.com',
    password: '123456'
  }
  useEffect(() => {
    // console.log(user)

    dispatch(signinStudent(studentLogin, navigate))
  }, [currentId, dispatch])

  return (
    <Grow in>
      <Container>
        <Grid
          container
          justifyContent='center'
          alignItems='stretch'
          spacing={3}
        >
          <Grid item xs={12} sm={6}>
            <Form currentId={currentId} setCurrentId={setCurrentId} />
          </Grid>
        </Grid>
      </Container>
    </Grow>
  )
}

export default Upload
