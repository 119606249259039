import {
  FETCH_ALL,
  CREATE,
  UPDATE,
  DELETE,
  LIKE,
  SENDBYEMAIL,
  POST_COUNT,
  STATUS,
  FETCH_PAG,
  START_LOADING,
  END_LOADING
} from '../constants/actionTypes'

const initialState = {
  posts: [],
  totalPosts: 0,
  status: false,
  isLoading: true
}

export default (state = initialState, action) => {
  switch (action.type) {
    case START_LOADING:
      return {
        ...state,
        isLoading: true
      }
    case END_LOADING:
      return {
        ...state,
        isLoading: false
      }
    case STATUS:
      return action.payload
    case POST_COUNT:
      return {
        totalPosts: action.payload
      }
    case FETCH_PAG:
      return {
        posts: action.payload,
        status: state.status
      }
    case FETCH_ALL:
      return {
        ...state,
        posts: action.payload.data,
        currentPage: action.payload.currentPage,
        numberOfPages: action.payload.numberOfPages,
        status: state.status
      }
    case LIKE:
      return {
        posts: state.posts.map((post) =>
          post._id === action.payload._id ? action.payload : post
        )
      }

    case SENDBYEMAIL:
      return {
        ...state,
        sendMail: action.payload
      }
    case CREATE:
      return {
        ...state,
        posts: [action.payload.data, ...state.posts],
        status: action.payload.success
      }

    case UPDATE:
      return state.posts.map((post) =>
        post._id === action.payload._id ? action.payload : post
      )
    case DELETE:
      return {
        ...state,
        posts: state.posts.filter((post) => post._id !== action.payload)
      }
    default:
      return state
  }
}
