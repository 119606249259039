import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1)
    }
  },
  paper: {
    padding: theme.spacing(2)
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  fileInput: {
    width: '100%',
    margin: '10px 0',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center'
  },
  formControlFile: { width: '100%' },
  CircularProgressbar: { width: '25%' },
  buttonSubmit: {
    marginBottom: 10
  },
  hiddenInput: {
    display: 'none'
  },
  boxOne: {
    marginBottom: '30px',
    textAlign: 'center',
    flex: 1,
    paddingTop: '30px',
    paddingBottom: '30px',
    padding: '30px 16px'
  },
  boxTwo: {
    display: 'flex',
    flex: 1,
    flexWrap: 'wrap',
    position: 'relative',
    textAlign: 'center',
    justifyContent: 'center',
    padding: '30px 16px'
  }
}))
