import React, { useState, useEffect } from 'react'
import {
  Container,
  Grow,
  Card,
  CardContent,
  CardActions,
  Button
} from '@material-ui/core/'
import Alert from '@material-ui/lab/Alert'
import { FacebookShareButton, TwitterShareButton } from 'react-share'

import { FacebookIcon, TwitterIcon } from 'react-share'
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom'
import { getPostById } from '../../actions/posts'
import useStyles from './styles'
import FsLightbox from 'fslightbox-react'
import { Player } from 'video-react'
// import SendFileToEMail from '../SendFileToEmail/SendFileToEMail'
// import SendSMS from '../SendSMS/SendSMS'
import { API_URL, URL } from '../../constants/actionTypes'
const Single = () => {
  const classes = useStyles()
  const params = useParams()
  const navigate = useNavigate()
  const { state } = useLocation()
  const [toggler, setToggler] = useState(false)
  const [lightboxFiles, setLightboxFiles] = useState([])

  const [post, setPost] = useState('')
  const callToUpload = () => {
    navigate(`/upload/`, { state: { page: 'single' } })
  }
  useEffect(() => {
    const fileArray = []
    const postId = params.id
    const fetchData = async () => {
      const data = await getPostById(postId)
      setPost(data)
      data.files.forEach((file) => {
        fileArray.push(API_URL + '/' + file.filePath)
      })
      setLightboxFiles(fileArray)
    }
    fetchData().catch(console.error)
  }, [params.id])
  return (
    <div>
      <Grow in>
        <Container>
          <Card className={classes.card}>
            <CardContent>
              {post ? (
                <div className={classes.postContent}>
                  {post.files.map((file) => (
                    <div key={file.fileName}>
                      {(file.fileType === 'image/jpeg' ||
                        file.fileType === 'image/jpg' ||
                        file.fileType === 'image/png') && (
                        <div className={classes.itemShareGroup}>
                          <img
                            className={classes.postContentImg}
                            alt=''
                            src={API_URL + '/' + file.filePath}
                            height='300px'
                          />
                          <div className={classes.socialSharingItem}>
                            Share:&nbsp;&nbsp;
                            <TwitterShareButton
                              className={classes.socialSharingBtn}
                              url={API_URL + '/' + file.filePath}
                            >
                              <TwitterIcon size={32} round={true} />
                            </TwitterShareButton>
                            <FacebookShareButton
                              className={classes.socialSharingBtn}
                              url={API_URL + '/' + file.filePath}
                            >
                              <FacebookIcon size={32} round={true} />
                            </FacebookShareButton>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ) : null}

              {post ? (
                <div>
                  {post.files.map((file) => (
                    <div
                      key={file.fileName}
                      className={classes.postContentVideo}
                    >
                      {(file.fileType === 'video/mp4' ||
                        file.fileType === 'video/mov' ||
                        file.fileType === 'video/quicktime') && (
                        <div className={classes.itemShareGroup}>
                          <Player
                            fluid={false}
                            width={800}
                            height={450}
                            playsInline
                            src={API_URL + '/' + file.filePath}
                          />
                          <div className={classes.socialSharingItem}>
                            <TwitterShareButton
                              url={API_URL + '/' + file.filePath}
                            >
                              <TwitterIcon size={32} round={true} />
                            </TwitterShareButton>
                            <FacebookShareButton
                              url={API_URL + '/' + file.filePath}
                            >
                              <FacebookIcon size={32} round={true} />
                            </FacebookShareButton>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ) : null}
            </CardContent>
            {post ? (
              <>
                <CardActions>
                  <div className={classes.socialSharing}>
                    <TwitterShareButton url={window.location.href}>
                      <TwitterIcon size={32} round={true} />
                    </TwitterShareButton>
                    <FacebookShareButton url={window.location.href}>
                      <FacebookIcon size={32} round={true} />
                    </FacebookShareButton>
                  </div>
                  {/* <div className={classes.ShareBox}>
                    <SendSMS data={post} />
                    <SendFileToEMail data={post} />
                  </div> */}
                </CardActions>

                <div style={{ textAlign: 'center' }}>
                  {state ? (
                    <Alert severity='success'>
                      Your files have been uploaded!
                    </Alert>
                  ) : null}
                  <Button
                    style={{ marginTop: '10px', marginBottom: '20px' }}
                    variant='contained'
                    onClick={callToUpload}
                    color='secondary'
                  >
                    Upload More Files
                  </Button>
                </div>

                <FsLightbox toggler={toggler} sources={lightboxFiles} />
              </>
            ) : null}
          </Card>
        </Container>
      </Grow>
    </div>
  )
}

export default Single
