import { makeStyles } from '@material-ui/core/styles'
import { deepPurple } from '@material-ui/core/colors'

export default makeStyles((theme) => ({
  appBar: {
    borderRadius: 0,
    margin: '0px 0px 30px 0px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 50px',
    backgroundRepeat: 'repeat',
    backgroundColor: '#3B3E4A',
    backgroundImage:
      'https://nationallaserinstitute.com/lp/wp-content/themes/nationallaser/images/bg-black.png'
  },
  heading: {
    color: 'rgba(0,183,255, 1)',
    textDecoration: 'none'
  },
  image: {
    marginLeft: '0px'
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '400px'
  },
  profile: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '400px'
  },
  userName: {
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    margin: '0 20px'
  },
  brandContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500]
  },
  menuLink: {
    color: '#fff',
    display: 'flex',
    justifyContent: 'space-evenly',
    flex: 2
  },
  menuLinkItem: {
    color: '#fff',
    textDecoration: 'none',
    cursor: 'pointer'
  },
  navlinks: {
    marginLeft: theme.spacing(10),
    display: 'flex'
  },
  logo: {
    flexGrow: '1',
    cursor: 'pointer'
  },
  link: {
    textDecoration: 'none',
    color: 'white',
    fontSize: '20px',
    marginLeft: theme.spacing(20),
    '&:hover': {
      color: 'yellow',
      borderBottom: '1px solid white'
    }
  }
}))
