import React, { useState, useRef } from 'react'
import {
  Avatar,
  Button,
  Typography,
  Paper,
  Grid,
  Container
} from '@material-ui/core'
// import { GoogleLogin } from 'react-google-login'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import useStyles from './styles'
import Input from './Input'
// import Icon from './Icon'
import { signup, signin, signinStudent } from '../../actions/auth'
const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: ''
}
const Auth = () => {
  const navigate = useNavigate()
  const success = useSelector((state) => state.auth)
  const classes = useStyles()
  const [formData, setFormData] = useState(initialState)
  const [showPassword, setShowPassword] = useState(false)
  const [isSignup, setIsSignup] = useState(false)
  const [loginMessage, setLoginMessage] = useState(true)
  const handleShowPassword = () =>
    setShowPassword((prevShowPassword) => !prevShowPassword)
  const studentUpload = async (e) => {
    const studentLogin = {
      email: 'student@nationallaserinstitute.com',
      password: '123456'
    }
    dispatch(signinStudent(studentLogin, navigate))
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    if (success.authData) {
      setLoginMessage(true)
    } else {
      setLoginMessage(false)
    }
    if (isSignup) {
      await dispatch(signup(formData, navigate))
    } else {
      dispatch(signin(formData, navigate))
    }
  }
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }
  const dispatch = useDispatch()
  const switchMode = () => {
    setIsSignup((prevIsSignup) => !prevIsSignup)
    handleShowPassword(false)
  }
  const user = JSON.parse(localStorage.getItem('profile'))
  if (user) {
    console.log(user)
    // navigate('/')
  }
  // const googleSuccess = async (res) => {
  //   const result = res?.profileObj // can not get property profileObj of undefined
  //   const token = res?.tokenId
  //   try {
  //     dispatch({ type: 'AUTH', data: { result, token } })
  //     navigate('/')
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  // const googleFailure = (error) => {
  //   console.log(error)
  //   console.log('Google Sign In was unsuccessful. Try Again Later')
  // }
  return (
    <Container component='main' maxWidth='xs'>
      <Paper
        className={classes.paper}
        elevation={3}
        style={{ display: 'none' }}
      >
        <Typography variant='h5'>Students Click Here!</Typography>

        <Button
          type='button'
          fullWidth
          variant='contained'
          color='secondary'
          className={classes.submit}
          onClick={studentUpload}
        >
          Share Your Files
        </Button>
      </Paper>
      <Paper
        className={classes.paper}
        elevation={3}
        style={{ marginTop: '30px' }}
      >
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography variant='h5'>
          {isSignup ? 'Instructor Sign Up' : 'Instructor Sign In'}
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {isSignup && (
              <>
                <Input
                  name='firstName'
                  label='First Name'
                  handleChange={handleChange}
                  autoFocus
                  half
                />

                <Input
                  name='lastName'
                  label='Last Name'
                  handleChange={handleChange}
                  half
                />
              </>
            )}
            <Input
              name='email'
              label='Email Address'
              handleChange={handleChange}
              type='email'
            />

            <Input
              name='password'
              label='Password'
              handleChange={handleChange}
              type={showPassword ? 'text' : 'password'}
              handleShowPassword={handleShowPassword}
            />
            {isSignup && (
              <Input
                name='confirmPassword'
                label='Repeat Password'
                handleChange={handleChange}
                type='password'
              />
            )}
          </Grid>
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            style={{ marginTop: '20px' }}
          >
            {isSignup ? 'Sign Up' : 'Sign In'}
          </Button>

          {/* <GoogleLogin
            clientId='813505075299-pmb2s4bdoce4veci63lq88l4car15m4j.apps.googleusercontent.com'
            render={(renderProps) => (
              <Button
                className={classes.googleButton}
                color='primary'
                fullWidth
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
                startIcon={<Icon />}
                variant='contained'
              >
                Google Sign In
              </Button>
            )}
            onSuccess={googleSuccess}
            onFailure={googleFailure}
            cookiePolicy='single_host_origin'
          /> */}
          {loginMessage ? null : success.error}
          <Grid container justifyContent='flex-end'>
            <Grid item>
              <Button onClick={switchMode}>
                {isSignup
                  ? 'Already have an account? Sign In'
                  : "Don't have an account? Sign Up"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  )
}

export default Auth
