import React, { useState, useEffect } from 'react'
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Typography
} from '@material-ui/core/'
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt'
import ThumbUpAltOutlined from '@material-ui/icons/ThumbUpAltOutlined'
import DeleteIcon from '@material-ui/icons/Delete'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import videothumbnail from '../../../images/default-video-thumbnail.jpeg'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { API_URL } from '../../../constants/actionTypes'
import { Link } from 'react-router-dom'
import { likePost, deletePost } from '../../../actions/posts'
import useStyles from './styles'
import { URL } from '../../../constants/actionTypes'
const Post = ({ post, setCurrentId }) => {
  const dispatch = useDispatch()
  const linkPost = `${URL}/posts/${post._id}`
  const [thumbnail, setThumbnail] = useState('')

  useEffect(() => {
    const fileArray = []
    if (post) {
      if (post.files) {
        post.files.forEach((file) => {
          if (
            file.fileType === 'image/jpeg' ||
            file.fileType === 'image/jpg' ||
            file.fileType === 'image/png'
          ) {
            fileArray.push(API_URL + '/' + file.filePath)
          } else {
            //console.log(post.videoThumbnail)
            if (post.videoThumbnail && post.videoThumbnail !== '') {
              fileArray.push(API_URL + '/' + post.videoThumbnail)
            } else {
              fileArray.push(videothumbnail)
            }
          }
        })
      }
      setThumbnail(fileArray.slice(0, 1))
    }
  }, [post])
  const classes = useStyles()
  const user = JSON.parse(localStorage.getItem('profile'))
  const Likes = () => {
    if (post) {
      if (post.likes) {
        return post.likes.find(
          (like) => like === (user?.result?.googleId || user?.result?._id)
        ) ? (
          <>
            <ThumbUpAltIcon fontSize='small' />
            &nbsp;
            {post.likes.length > 2
              ? `You and ${post.likes.length - 1} others`
              : `${post.likes.length} like${post.likes.length > 1 ? 's' : ''}`}
          </>
        ) : (
          <>
            <ThumbUpAltOutlined fontSize='small' />
            &nbsp;{post.likes.length}{' '}
            {post.likes.length === 1 ? 'Like' : 'Likes'}
          </>
        )
      }
      return (
        <>
          <ThumbUpAltOutlined fontSize='small' />
          &nbsp;Like
        </>
      )
    }
  }
  return (
    <Card className={classes.card}>
      <Link to={linkPost} className='link'>
        {thumbnail !== '' ? (
          <div>
            {thumbnail.map((file) => (
              <div key={file}>
                <CardMedia
                  className={classes.media}
                  image={file}
                  // title={post.title}
                />
              </div>
            ))}
          </div>
        ) : (
          <div>
            <CardMedia className={classes.media} image={videothumbnail} />
          </div>
        )}

        <div className={classes.overlay}>
          {/* <Typography variant='h6'>{post.name}</Typography> */}
          <Typography variant='body2'>
            {moment(post.createdAt).fromNow()}
          </Typography>
        </div>
        {user?.result?.googleId === post?.creator ||
          (user?.result?._id === post?.creator && (
            <div className={classes.overlay2}>
              {/* <Button
                style={{ color: 'white' }}
                size='small'
                onClick={() => setCurrentId(post._id)}
              >
                <MoreHorizIcon />
              </Button> */}
            </div>
          ))}
        {/* 
      <Typography
        className={classes.title}
        gutterBottom
        variant='h5'
        component='h2'
      >
        {post.title}
      </Typography>
      <CardContent>
        <Typography variant='body2' color='textSecondary' component='p'>
          {post.message}
        </Typography>
      </CardContent> */}
        {/* <CardActions className={classes.cardActions}>
        <Button
          size='small'
          color='primary'
          onClick={() => dispatch(likePost(post._id))}
          disabled={!user?.result}
        >
          <Likes />
        </Button>
        {user?.result?.googleId === post?.creator ||
          (user?.result?._id === post?.creator && (
            <Button
              size='small'
              color='primary'
              onClick={() => dispatch(deletePost(post._id))}
            >
              <DeleteIcon fontSize='small' /> Delete
            </Button>
          ))}
      </CardActions> */}
      </Link>
    </Card>
  )
}

export default Post
