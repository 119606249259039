import axios from 'axios'
import { API_URL } from '../constants/actionTypes'
const API = axios.create({
  baseURL: API_URL
})

//const url = 'http://localhost:5000/posts'
API.interceptors.request.use((req) => {
  if (localStorage.getItem('profile')) {
    req.headers.authorization = `Bearer ${
      JSON.parse(localStorage.getItem('profile')).accessToken
    }`
  }
  return req
})

export const postCount = (newUid) => API.get(`/posts/postcount?v=${newUid}`)
export const getPostById = (id) => API.get(`/posts/${id}`)
export const fetchPosts = (newUid, page) =>
  API.get(`/posts?page=${page}&v=${newUid}`)
export const fetchPostsByUser = (newUid, page, userId) =>
  API.get(`/posts?page=${page}&userId=${userId}&v=${newUid}`)
export const fetchPostsPag = (newUid, queryParams) =>
  API.post(`/posts?v=${newUid}`, queryParams)
//export const createPost = (newPost) => API.post('/posts', newPost)
export const createPost = (newPost, options) =>
  API.post('/posts/multipleFile', newPost, options)

export const likePost = (id) => API.patch(`/posts/${id}/likePost`)
export const updatePost = (id, updatedPost) =>
  API.patch(`/posts/${id}`, updatedPost)
export const deletePost = (id) => API.delete(`/posts/${id}`)
export const sendFileByEmail = (formData) =>
  API.post('/posts/sendfilebyemail', formData)

export const signIn = (formData) => API.post('/user/signin', formData)
export const signUp = (formData) => API.post('/user/signup', formData)
