import React, { useEffect } from 'react'
import { Pagination, PaginationItem } from '@material-ui/lab'
import useStyles from './styles'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getPosts } from '../actions/posts'
const Paginate = ({ page }) => {
  const dispatch = useDispatch()
  let { numberOfPages } = useSelector((state) => state.posts)

  const user = JSON.parse(localStorage.getItem('profile'))
  if (!user) {
    numberOfPages = 0
  }
  const classes = useStyles()
  useEffect(() => {
    if (user) {
      if (page) dispatch(getPosts(page, user.result._id))
    }
  }, [page])
  return (
    <Pagination
      classes={{ ul: classes.ul }}
      count={numberOfPages}
      page={Number(page) || 1}
      variant='outlined'
      color='primary'
      renderItem={(item) => (
        <PaginationItem
          {...item}
          component={Link}
          to={`/posts?page=${item.page}`}
        />
      )}
    />
  )
}

export default Paginate
