import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(() => ({
  ul: {
    justifyContent: 'space-around',
    marginTop: '20px',
    paddingTop: '10px',
    paddingBottom: '10px'
  }
}))
