import { makeStyles } from '@material-ui/core/styles'
import '../../../node_modules/video-react/dist/video-react.css' // import css
export default makeStyles({
  media: {
    height: 0,
    paddingTop: '56.25%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    backgroundBlendMode: 'darken'
  },
  border: {
    border: 'solid'
  },
  fullHeightCard: {
    height: '100%'
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: '15px',
    height: '100%',
    position: 'relative'
  },
  overlay: {
    position: 'absolute',
    top: '20px',
    left: '20px',
    color: 'white'
  },
  overlay2: {
    position: 'absolute',
    top: '20px',
    right: '20px',
    color: 'white'
  },
  grid: {
    display: 'flex'
  },
  details: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '20px'
  },
  title: {
    padding: '0 16px'
  },
  cardActions: {
    padding: '0 16px 8px 16px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  postContent: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    textAlign: 'center'
  },
  postContentImg: {
    width: '60%',
    maxWidth: '600px',
    height: 'auto'
  },
  postContentVideo: {
    display: 'flex',
    flex: 1,
    textAlign: 'center',
    alignContent: 'center',
    justifyContent: 'center'
  },
  ShareBox: {
    display: 'flex',
    width: '100%',
    flex: 1,
    flexDirection: 'column'
  },
  lightboxBtnWrap: {
    display: 'flex',

    justifyContent: 'center'
  },
  lightboxBtn: {
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'center',
    justifyContent: 'center'
  },
  socialSharing: {
    position: 'fixed',
    right: '10px',
    top: '45%',
    display: 'flex',
    flexDirection: 'column'
  },

  socialSharingItem: {
    display: 'flex',
    marginTop: '20px',
    alignItems: 'center'
  },
  itemShareGroup: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  socialSharingBtn: {
    marginRight: '5px'
  }
})
