import React, { useState, useEffect } from 'react'
import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
  Button,
  Avatar
} from '@material-ui/core'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import decode from 'jwt-decode'
import { useDispatch } from 'react-redux'
import MenuIcon from '@material-ui/icons/Menu'
import { URL } from '../../constants/actionTypes'

const useStyles = makeStyles(() => ({
  link: {
    textDecoration: 'none',
    color: 'blue',
    fontSize: '20px'
  },
  icon: {
    color: 'white',
    fontSize: '2em'
  }
}))

const DrawerComponent = () => {
  const classes = useStyles()
  const [openDrawer, setOpenDrawer] = useState(false)
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')))
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const logout = () => {
    dispatch({ type: 'LOGOUT' })
    navigate(`${URL}/auth`)
    window.location.reload()
    setUser(null)
  }
  useEffect(() => {
    const token = user?.token
    // JWT...
    if (token) {
      const decodedToken = decode(token)
      if (decodedToken.exp * 1000 < new Date().getTime()) logout()
    }
    setUser(JSON.parse(localStorage.getItem('profile')))
  }, [location])

  return (
    <>
      <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <List>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to={`${URL}/upload`} className={classes.link}>
                <Typography>Upload</Typography>
              </Link>
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to={`${URL}/posts`} className={classes.link}>
                <Typography>view all uploads</Typography>
              </Link>
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to={`${URL}/auth`} className={classes.link}>
                <Typography>Instructor Sign in</Typography>
              </Link>
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              {user ? (
                <div className={classes.profile} style={{ display: 'flex' }}>
                  <Typography className={classes.userName} variant='h6'>
                    {user.result.name}
                  </Typography>
                </div>
              ) : (
                ''
                // <Button
                //   variant='contained'
                //   component={Link}
                //   to={`${URL}/auth`}
                //   color='secondary'
                // >
                //   Sign In
                // </Button>
              )}
            </ListItemText>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              {user ? (
                <div className={classes.profile}>
                  <Button
                    variant='contained'
                    className={classes.logout}
                    color='secondary'
                    onClick={logout}
                    style={{ color: 'white' }}
                  >
                    Logout
                  </Button>
                </div>
              ) : (
                ''
                // <Button
                //   variant='contained'
                //   component={Link}
                //   to={`${URL}/auth`}
                //   color='secondary'
                // >
                //   Sign In
                // </Button>
              )}
            </ListItemText>
          </ListItem>
        </List>
      </Drawer>
      <IconButton
        onClick={() => setOpenDrawer(!openDrawer)}
        className={classes.icon}
      >
        <MenuIcon style={{ fontSize: '3rem' }} />
      </IconButton>
    </>
  )
}
export default DrawerComponent
