import { AUTH, LOGOUT, AUTH_FAIL } from '../constants/actionTypes'

const authReducer = (state = { authData: null }, action) => {
  switch (action.type) {
    case AUTH:
      //  console.log(action?.data)
      localStorage.setItem('profile', JSON.stringify({ ...action?.data }))
      return { ...state, authData: action?.data }
    case AUTH_FAIL:
      //  console.log(action?.data)
      return { ...state, error: action?.data }
    case LOGOUT:
      // console.log(action?.data)
      localStorage.clear()
      return { ...state, authData: null }

    default:
      return state
  }
}

export default authReducer
