import React from 'react'
import {
  Container,
  useMediaQuery,
  Typography,
  useTheme
} from '@material-ui/core'
import { BrowserRouter, Routes, Route, Navigate, Link } from 'react-router-dom'
import Navbar from './components/Navbar/Navbar'
import Home from './components/Home/Home'
import Auth from './components/Auth/Auth'
import Single from './components/Single/Single'
import Upload from './components/Upload/Upload'
import { URL } from './constants/actionTypes'

const App = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <BrowserRouter>
      <Navbar />
      {isMobile ? (
        <div className='mobileMenu'>
          <Link to={`${URL}/upload`}>
            <Typography>Upload</Typography>
          </Link>
          <Link to={`${URL}/posts`}>
            <Typography>view all uploads</Typography>
          </Link>
        </div>
      ) : null}
      <Container maxWidth='xl'>
        <Routes>
          <Route
            path={URL + '/'}
            exact
            element={<Navigate to={URL + '/upload'} />}
          />
          <Route path={URL + '/auth'} exact element={<Auth />} />
          <Route path={URL + '/upload'} exact element={<Upload />} />

          <Route path={URL + '/posts'} exact element={<Home />} />
          <Route path={URL + '/posts/:id'} exact element={<Single />} />
        </Routes>
      </Container>
    </BrowserRouter>
  )
}

export default App
