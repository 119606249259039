import React from 'react'
import { Grid, CircularProgress } from '@material-ui/core'
import { useSelector } from 'react-redux'
import Post from './Post/Post'
import useStyles from './styles'
import { useDispatch } from 'react-redux'
// import { Table } from 'reactstrap';

const Posts = ({ setCurrentId }) => {
  const dispatch = useDispatch()
  const { posts, isLoading } = useSelector((state) => state.posts)

  const classes = useStyles()
  if (!posts && !isLoading) return 'No Posts'
  return isLoading ? (
    <CircularProgress />
  ) : (
    <Grid
      className={classes.container}
      container
      alignItems='stretch'
      spacing={3}
    >
      {posts.map((post) => (
        <Grid key={post._id} item xs={6} md={3}>
          <Post post={post} setCurrentId={setCurrentId} />
        </Grid>
      ))}
      {posts.length > 0 ? (
        ''
      ) : (
        <p style={{ flexBasis: '100%', padding: '0px 20px', fontSize: '20px' }}>
          No files have been uploaded yet
        </p>
      )}
    </Grid>
  )
}

export default Posts
