export const CREATE = 'CREATE'
export const UPDATE = 'UPDATE'
export const DELETE = 'DELETE'
export const FETCH_ALL = 'FETCH_ALL'
export const LIKE = 'LIKE'
export const LOGOUT = 'LOGOUT'
export const AUTH = 'AUTH'
export const AUTH_FAIL = 'AUTH_FAIL'
export const SENDBYEMAIL = 'SENDBYEMAIL'
export const POST_COUNT = 'POST_COUNT'
export const FETCH_PAG = 'FETCH_PAG'
export const START_LOADING = 'START_LOADING'
export const END_LOADING = 'END_LOADING'
export const STATUS = 'STATUS'
export const URL = ''
export const API_URL = 'https://upload.nationallaserinstitute.com'
// export const API_URL = 'http://localhost:5000'
