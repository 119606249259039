import React, { useState, useEffect } from 'react'
import {
  AppBar,
  Avatar,
  Typography,
  Toolbar,
  Button,
  useTheme
} from '@material-ui/core'
import useStyles from './styles'
import { Link } from 'react-router-dom'
import logo from '../../images/logo-approved.png'
import { URL } from '../../constants/actionTypes'
import DrawerComponent from './Drawer'
console.ignoredYellowBox = ['Warning:']
const Navbar = () => {
  const classes = useStyles()
  //const user = null

  const theme = useTheme()

  return (
    <AppBar className={classes.appBar} position='static' color='inherit'>
      <div className={classes.brandContainer}>
        <Link to={URL + '/upload'}>
          <img className={classes.image} src={logo} alt='icon' height='75' />
        </Link>
      </div>

      <Toolbar className={classes.toolbar}>
        <DrawerComponent />
      </Toolbar>
    </AppBar>
  )
}

export default Navbar
