import {
  FETCH_ALL,
  CREATE,
  UPDATE,
  DELETE,
  LIKE,
  SENDBYEMAIL,
  POST_COUNT,
  FETCH_PAG,
  START_LOADING,
  END_LOADING,
  URL
} from '../constants/actionTypes'

import uuid from 'react-uuid'

import * as api from '../api/index.js'
export const getPostCount = () => async (dispatch) => {
  try {
    const newUid = uuid()

    const { data } = await api.postCount(newUid)
    dispatch({ type: POST_COUNT, payload: data.postcount })
  } catch (error) {
    console.log(error)
  }
}
export const getPosts = (page, userId) => async (dispatch) => {
  try {
    const newid = uuid()
    dispatch({ type: START_LOADING })
    //  const { data } = await api.fetchPosts(newid)
    //const { data } = await api.fetchPosts(newid, page)
    const { data } = await api.fetchPostsByUser(newid, page, userId)
    dispatch({ type: FETCH_ALL, payload: data })
    dispatch({ type: END_LOADING })
  } catch (error) {
    console.log(error.message)
  }
}

export const getPostsPag = (currentPage, userId) => async (dispatch) => {
  try {
    const queryParams = {}
    queryParams['page'] = currentPage //Page Number
    queryParams['pagination'] = 10 //Number Of records on Page
    queryParams['userId'] = userId //Number Of records on Page

    const newid = uuid()

    //  const { data } = await api.fetchPosts(newid)
    const { data } = await api.fetchPostsPag(newid, queryParams)

    dispatch({ type: FETCH_PAG, payload: data.postMessages })
  } catch (error) {
    console.log(error.message)
  }
}

export const getPostById = async (id) => {
  try {
    const { data } = await api.getPostById(id)

    return data.post
    // dispatch({ type: FETCH_SINGLEPOST, payload: data })
  } catch (error) {
    console.log(error.message)
  }
}
export const createPost = (post, options, navigate) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING })
    const { data } = await api.createPost(post, options)
    //  console.log(data.data._id)
    navigate(`/posts/${data.data._id}`, { state: { page: 'upload' } })
    dispatch({ type: CREATE, payload: data })
    // dispatch({ type: STATUS, payload: data.success })
  } catch (error) {
    console.log(error.message)
  }
}

export const sendFileToEmail =
  (emails, message, filesArr) => async (dispatch) => {
    try {
      const { data } = await api.sendFileByEmail(emails, message, filesArr)
      dispatch({ type: SENDBYEMAIL, payload: data.message })
    } catch (error) {
      dispatch({ type: SENDBYEMAIL, payload: false })

      console.log(error)
    }
  }
export const updatePost = (id, post) => async (dispatch) => {
  try {
    const { data } = await api.updatePost(id, post)

    dispatch({ type: UPDATE, payload: data })
  } catch (error) {
    console.log(error.message)
  }
}

export const likePost = (id) => async (dispatch) => {
  try {
    const { data } = await api.likePost(id)

    dispatch({ type: LIKE, payload: data })
  } catch (error) {
    console.log(error.message)
  }
}

export const deletePost = (id) => async (dispatch) => {
  try {
    await api.deletePost(id)

    dispatch({ type: DELETE, payload: id })
  } catch (error) {
    console.log(error.message)
  }
}
